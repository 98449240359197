import userAPI from "@/api/userAPI.js";
import notifications from "../common/notifications";
import Header from "@/components/Header.vue";
import store from "@/store/index";
import validations from "@/validations/index";

export default {
  components: {
    Header,
    notifications,
  },
  data: () => ({
    errorType: "",
    message: "",
    loading: false,
    formData: {
      user_id: null,
      OldPassword: null,
      NewPassword: null,
      ConfirmPassword: null,
      permission: {
        allowAdd: false,
      },
    },
  }),
  mounted() {
    //console.log(validations.hasValue('     '));
  },

  methods: {
    changePassword: function (e) {
      e.preventDefault();

      this.loading = true;
      this.vrNotify.visible = false;
      let isValid = true;

      if (isValid) {
        isValid = validations.hasValue(this.formData.OldPassword);
      }
      if (isValid) {
        isValid = validations.hasValue(this.formData.NewPassword);
      }
      if (isValid) {
        isValid = validations.hasValue(this.formData.ConfirmPassword);
      }

      if (!isValid) {
        //Show error message
        this.vrNotify.message = store.state.required.message;
        this.vrNotify.type = store.state.required.type;
        this.vrNotify.visible = store.state.required.visible;
        this.loading = false;
        return false;
      }

      if (this.formData.NewPassword !== this.formData.ConfirmPassword) {
        //Show message for invalid password
        this.vrNotify.message =
          "New Passord and Confirm Pasword are not same. ";
        this.vrNotify.type = store.state.required.type;
        this.vrNotify.visible = store.state.required.visible;
        this.loading = false;
        return false;
      }

      this.formData.user_id = store.getters.user.user_id;

      userAPI
        .changePassword(this.formData)
        .then((response) => {
          if (response != null && response.data != null) {
            this.vrNotify.type = response.data.error_type;
            this.vrNotify.message = response.data.message;
            this.vrNotify.visible = true;

            if (response.data.status) {
              this.$router.push("login");
            }
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {},
  computed: {
    vrNotify() {
      return store.state.notify;
    },
  },
  async beforeMount() {
    //Remove all values from browser's cache
    //sessionStorage.clear();

    await Promise.all([
      // this.getBusinessModelOptionsData(),
      // this.getPriorityOptionsData(),
    ]);
  },
  beforeCreate() {
    //validations.SearchPermissionHandling(this.permission)
  },
};

//alert('AL');
